
/* .logo{background: url("images/new-celebrations-square-logo.jpg");background-repeat: no-repeat; width: 200px;height: 200px;} */
.btn{background: linear-gradient(90deg, hsla(46, 66%, 50%, 1) 20%, hsla(46, 78%, 41%, 1) 100%)!important;border:none!important}
.btn.btn-dark{background: #000!important}
.btn.btn-dark.bg-transparent{background-color: transparent!important;border:1px solid #000!important;}
.btn-outline-light{background: none!important;border:1px solid #f8f9fa!important;}
.btn.btn-secondary{background: #333!important;}
@media only screen and (max-width: 767px) {
  #slide-navbar-collapse {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
    width: 200px;
    height: 100%;
    background-color: #000;
    overflow: auto;
    padding-left:25px;
    padding-top: 20px;
    .btn-close{background-color: #fff;}
  }
  .menu-overlay {
    display: none;
    background-color: #adadad;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    filter: alpha(opacity=20);
    /* IE7 & 8 */
    position: fixed;
    right: 0;
    top: 0;
    z-index: 49;
  }
}

.text-stroke{
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}
.close-icon .navbar-toggler-icon{background-image: var(--bs-close-icon-bg)!important;}



.dm-sans-csquare {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
#root{ font-family: "DM Sans", sans-serif;}
body {
  margin: 0;
  background-color: #273B76!important;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*a50706  f99525 0D014D*/ 
.btn-color-1{background-color: #a50706}
.btn-color-2{background-color: #f99525}
.bg-color-1-opa{background-color: rgb(12, 0, 76, 0.8);}
.bg-color-1-opa70{background-color: rgb(0, 0, 0, 0.7);}

.bg-color-1{background-color: rgb(165, 7, 6, 1);}
.color-palette-1{color:#a5d22b}  
.logo-color-1{
  color:#B52954
  
}
.logo-color-2{
  color:#D2AC2B;
}
.navbar-toggler{border:none!important}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bookNow {
  /* background-image: url('../src/images/main-party-style-pg.jpg'); */
  background: linear-gradient(0deg, rgba(255,255,255,1) 6%,  rgba(55,76,145,1) 71%);

  background-repeat: repeat;
  background-position: bottom;
}
.select_theater {
  background-color: white;
}
.theaterContainer {
  cursor: pointer;
  .viewGallery a{color: #fff;text-decoration: none;}
}
.theater_name {
 
}
.theater_price {
  display: flex;
  justify-content: space-between;
}
.cs_cards {
  margin: 2px 3px;
  cursor: pointer;
}

.cs-select {
  border: 2px solid #000 !important;
  border-radius: 18px !important;
  color: #000!important;
  font-weight: 500!important;
  option:disabled{
    color:#ccc!important;
    cursor: default;
  }
  option{
    font-weight: 500!important;
    cursor: pointer!important;
  }
}
.margin10 {
  margin: 10px 0px;
}
.theater_green {
  color: green;
}
.modal_footer_btn {
  justify-content: space-between !important;
}
.c2square-addons-list{
  li{
    text-align: center;
    padding: 0 10px;
    img{ width: 100%!important;height:100%!important;border: 1px solid rgb(249, 149, 37); background:  rgb(249, 149, 37,0.3);border-radius: 10px;}

  } 
.slick-slide{width: 125px!important;height:135px!important;}
}

.card-img, .card-img-top{height: 250px;}
.card-footer{border:none!important;}
.inner-pages{background: linear-gradient(0deg, rgba(255,255,255,1) 6%,  rgba(55,76,145,1) 71%); padding: 20px 0;
ul{margin-top: 10px;
  
    li{margin-top: 10px;}
  
  
}}

.admin-filter-section {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.admin-dashboard-card {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 15px;
}

.empty-textbox {
  border: 1px solid #f99525;
  border-radius: 5px;
  padding: 5px;
}

.admin-menu-selected {
  background-color: #374C91!important;
  color: #fff !important;
}
.metalinks a.nav-link{color:#000;}
.admin-meta-list{
  width: 30%;
  background-color:beige;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 0.8rem;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.theaterDetails{@media (max-width: 576px)  {width: auto;}
  @media (min-width: 576px)  {
    width: 750px;
  }
}
#csquare-addons .modal-content .modal-body{max-height: 65vh;overflow-y: auto;}



.orange-color-bg{background-color: #F89525;} /*248 149 37*/
.light-orange-color-bg{background-color: #FEF4E9;} /*254 244 233*/
.border-orange{border-color: rgb(12, 0, 76)!important;}
.selected-addon{border-color:rgb(12, 0, 76, 0.6)!important;background-color: rgb(12, 0, 76, 0.6);}
.modal-body .row .selected-addon span{color:#fff!important;}
.modal-body .row .addon-list{border-color:rgb(12, 0, 76, 0.2)!important}
#reviewDetails li img{margin-right: 10px;}
#reviewDetails li{margin-bottom: 10px;font-weight: 500;}
#features .card{border: 3px solid #0C004C!important;border-radius: 15px;}
#features .card h3{color:#000!important}
.modal-header .btn-close {color: #fff!important;}
@media (max-width: 576px) {
#cs-gallery{
  ul{column-count: 2!important;}
}
#checkSlot input{
width: 95%!important;
}
 }
 #checkSlot input{width:400px;}
 @media (max-width: 576px) {
 .theaterDetails .slick-slide img{width:330px!important;height: 250px!important;}
 }.slick-prev:before, .slick-next:before{color:#000!important;}
 .cs2-social-links li i{margin: 0 5px!important;}
 .event-review-table tr td:first-child{text-align: left;}
 .event-review-table tr td:nth-child(2){text-align: right;}
 .event-review-table tr td img, .event-review-table tr td:first-child i{margin-right: 10px;}
 .event-review-table tr:last-child{height: 50px;}