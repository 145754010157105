/* NavBar.module.css */

.navbar {
  background-color: #0C004C!important;
  margin-right: auto !important;
}
.mobileBtns{  background-color: #0C004C!important;
}

.navLink {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  transition: color 0.3s, background-color 0.3s;
  text-decoration: none;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  color:#fff!important;
}
.leftNav {
  margin-left: auto !important;
}
/* rgb(249, 149, 37)  */
.navBarTopSection {background-color:#374C91;color:rgb(243, 244, 246); a{color:rgb(255, 255, 255); }i{color:rgb(255, 255, 255);}
border-bottom-left-radius: 15px;border-bottom-right-radius: 15px;}
.navBarTopSection a,.navBarTopSection a:hover{text-decoration: none;color:#fff;}
@media (min-width: 577px) {
  .navbar-nav a.nav-link{color:#fff!important;}

}
@media (max-width: 576px)  {
  .navbar-nav a.nav-link{color:#000!important;}

}
/* #csquare-nav .navbar-nav .nav-link.active, .navbar-nav .show>.nav-link{color:#fff!important} */